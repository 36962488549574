<template>
  <main>
    <section class="page_404">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="col-sm-10 col-sm-offset-1 text-center">
              <div class="four_zero_four_bg">
                <h1 class="text-center">404</h1>
              </div>

              <div class="contant_box_404">
                <h3 class="h2">Looks like you're lost</h3>

                <p>The page you are looking for was <strong>Not Found!</strong></p>

                <a class="link_404"
                  ><router-link class="notfound" to="/home"
                    >Go Home</router-link
                  ></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.page_404 {
  text-align: center;
  text-align: -webkit-center;
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.notfound {
  color: #fff;
  text-decoration: none;
}

.link_404 {
    text-decoration: none;
  padding: 10px 20px;
  background: mediumpurple;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}
</style>